import { Image } from "@/Components/common/Image"
import { ArrowButton } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"

interface Props {
  title: string
  subTitle: string
  cover: string
  id: string
  date: [string, string]
}
export const BlogCard = ({ date: [month, value], cover, subTitle, title, id }: Props) => {
  const histroy = useHistory()
  return (
    <div
      className="blog-card"
      style={{ cursor: "pointer" }}
      onClick={() => histroy.push(`/blog/view/${id}`)}
    >
      <div className="blog-card__header">
        <Image
          src={`blog/${cover}_xs.png`}
          style={{
            borderRadius: 20,
          }}
        />
      </div>
      <div className="blog-card__title">
        <Msg id={title} />
      </div>
      <div className="blog-card__date">
        <FormattedMessage id={month} /> {value}
      </div>
      <div className="blog-card__min-content">
        <Msg id={subTitle} />
      </div>
      <div className="blog-card__bottom">
        <ArrowButton title={<FormattedMessage id="read_more" />} to={`/blog/view/${id}`} />
      </div>
    </div>
  )
}
