import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { BlogCard } from "./BlogCard"
import { blogs } from "./blogs"
import { Header } from "./Header"
import { Pagination } from "./Pagination"
import "./style.sass"

export const Blogs = () => {
  const history = useHistory()
  const { page: pageNumber } = useParams<{ page: string }>()
  const [page, setPage] = useState(pageNumber != null ? parseInt(pageNumber) : 0)
  return (
    <Layout className="blogs">
      <Container className="blogs-container">
        <Header />
        <div className="blogs__grid">
          {blogs.slice(page * 4, page * 4 + 4).map((blog) => (
            <BlogCard
              date={blog.date as [string, string]}
              id={blog.id}
              cover={blog.cover}
              subTitle={blog.subTitle}
              title={blog.title}
            />
          ))}
        </div>
        <Pagination
          total={blogs.length}
          page={page}
          perPage={4}
          onChange={(page) => {
            setPage(page)
            history.push("/blog/" + page)
          }}
        />
      </Container>
    </Layout>
  )
}
