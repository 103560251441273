import { Image } from "@/Components/common/Image"
import { FaqBackButton } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { useMemo } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import { blogs } from "./blogs"
import { RelatedBlogs } from "./RelatedBlogs"

export const BlogContent = () => {
  const { blog_id } = useParams<{ blog_id: string }>()
  const blog = useMemo(() => {
    return blogs.find((blog) => blog.id === blog_id)
  }, [blog_id])
  const [month, blogDate] = blog.date
  return (
    <Layout>
      <Container>
        <div className="blog-content">
          <FaqBackButton title="Back" to="/blog" />
          <div className="blog-content__header">
            <Image className="mobile-cover" src={`blog/${blog.cover}.png`} />
            <Image src={`blog/${blog.cover}_lg.png`} className="blog-content__cover" />
            <div className="titlebar">
              <p>
                <Msg id={blog.title} vars={{ b: (chunk: string) => chunk }} />
              </p>
              <span>
                <FormattedMessage id={month} /> {blogDate}
              </span>
            </div>
          </div>
          <div className="blog-content__content">{blog.content}</div>
        </div>
        <RelatedBlogs />
      </Container>
    </Layout>
  )
}
