import { Msg } from "@/Components/Msg"

export const blogs = [
  {
    id: "1",
    title: "blog1.0",
    cover: "blog1",
    subTitle: "blog1.1",
    date: ["june", "10, 2024"],
    content: (
      <>
        <Msg id="blog1.1" tag="p" />
        <Msg id="blog1.2" tag="p" />
        <Msg id="blog1.3" tag="p" />
        <ul style={{ marginLeft: 20, listStyleType: "number", paddingLeft: 20 }}>
          <Msg id="blog1.4" tag="li" />
          <Msg id="blog1.5" tag="li" />
          <Msg id="blog1.6" tag="li" />
        </ul>
        <Msg id="blog1.7" tag="p" />
        <Msg id="blog1.8" tag="p" />
        <ul style={{ marginLeft: 20, listStyleType: "disc", paddingLeft: 20 }}>
          <Msg id="blog1.9" tag="li" />
          <Msg id="blog1.10" tag="li" />
          <Msg id="blog1.11" tag="li" />
        </ul>

        <Msg id="blog1.12" tag="p" />

        <ul style={{ marginLeft: 20, listStyleType: "disc", paddingLeft: 20 }}>
          <Msg id="blog1.13" tag="li" />
          <Msg id="blog1.14" tag="li" />
          <Msg id="blog1.15" tag="li" />
          <Msg id="blog1.16" tag="li" />
        </ul>
        <Msg id="blog1.17" tag="p" />
      </>
    ),
  },
  {
    id: "2",
    title: "blog2.0",
    cover: "blog2",
    subTitle: "blog2.1",
    date: ["september", "03,2024"],
    content: (
      <>
        <Msg id="blog2.1" tag="p" />
        <Msg id="blog2.2" tag="p" />
        <Msg id="blog2.3" tag="p" />
        <Msg id="blog2.4" tag="p" />
        <Msg id="blog2.5" tag="p" />
        <Msg id="blog2.6" tag="p" />
        <Msg id="blog2.7" tag="p" />
        <Msg id="blog2.8" tag="p" />
        <Msg id="blog2.9" tag="p" />
        <Msg id="blog2.10" tag="p" />
        <Msg id="blog2.11" tag="p" />
        <ul style={{ marginLeft: 20, listStyleType: "disc", paddingLeft: 20 }}>
          <Msg id="blog2.12" tag="li" />
          <Msg id="blog2.13" tag="li" />
          <Msg id="blog2.14" tag="li" />
        </ul>
        <Msg id="blog2.15" tag="p" />
      </>
    ),
  },
  {
    id: "3",
    title: "blog3.0",
    cover: "blog3",
    subTitle: "blog3.1",
    date: ["july", "23, 2024"],
    content: (
      <>
        <Msg id="blog3.1" tag="p" />
        <Msg id="blog3.2" tag="p" />
        <Msg id="blog3.3" tag="p" />
        <Msg id="blog3.4" tag="p" />
        <Msg id="blog3.5" tag="p" />
        <Msg id="blog3.6" tag="p" />
        <Msg id="blog3.7" tag="p" />
      </>
    ),
  },
  {
    id: "4",
    title: "blog4.0",
    cover: "blog4",
    subTitle: "blog4.1",
    date: ["may", "13, 2024"],
    content: (
      <>
        <Msg id="blog4.1" tag="p" />
        <Msg id="blog4.2" tag="p" />
        <Msg id="blog4.3" tag="p" />
        <Msg id="blog4.4" tag="p" />
        <Msg id="blog4.5" tag="p" />
        <Msg id="blog4.6" tag="p" />
        <Msg id="blog4.7" tag="p" />
        <Msg id="blog4.8" tag="p" />
        <Msg id="blog4.9" tag="p" />
        <Msg id="blog4.10" tag="p" />
        <Msg id="blog4.11" tag="p" />
        <Msg id="blog4.12" tag="p" />
        <Msg id="blog4.13" tag="p" />
        <Msg id="blog4.14" tag="p" />
        <Msg id="blog4.15" tag="p" />
        <Msg id="blog4.16" tag="p" />
      </>
    ),
  },
  {
    id: "5",
    title: "blog5.0",
    cover: "blog5",
    subTitle: "blog5.1",
    date: ["august", "06, 2024"],
    content: (
      <>
        <Msg id="blog5.1" tag="p" />
        <Msg id="blog5.2" tag="p" />
        <Msg id="blog5.3" tag="p" />
        <ul style={{ marginLeft: 20, listStyleType: "number", paddingLeft: 20 }}>
          <Msg id="blog5.4" tag="li" />
          <Msg id="blog5.5" tag="li" />
          <Msg id="blog5.6" tag="li" />
          <Msg id="blog5.7" tag="li" />
          <Msg id="blog5.8" tag="li" />
          <Msg id="blog5.9" tag="li" />
        </ul>
        <Msg id="blog5.10" tag="p" />
      </>
    ),
  },
  {
    id: "6",
    title: "blog6.0",
    cover: "blog6",
    subTitle: "blog6.1",
    date: ["january", "03, 2025"],
    content: (
      <>
        <Msg id="blog6.1" tag="p" />
        <Msg id="blog6.2" tag="p" />
        <Msg id="blog6.3" tag="p" />
        <Msg id="blog6.4" tag="p" />
        <Msg id="blog6.5" tag="p" />
        <Msg id="blog6.6" tag="p" />
      </>
    ),
  },
  {
    id: "7",
    title: "blog7.0",
    cover: "blog7",
    subTitle: "blog7.1",
    date: ["october", "02, 2024"],
    content: (
      <>
        <Msg id="blog7.1" tag="p" />
        <Msg id="blog7.2" tag="p" />
        <Msg id="blog7.3" tag="p" />
        <Msg id="blog7.4" tag="p" />
        <Msg id="blog7.5" tag="p" />
      </>
    ),
  },
  {
    id: "8",
    title: "blog8.0",
    cover: "blog8",
    subTitle: "blog8.1",
    date: ["september", "24, 2024"],
    content: (
      <>
        <Msg id="blog8.1" tag="p" />
        <Msg id="blog8.2" tag="p" />
        <Msg id="blog8.3" tag="p" />
        <Msg id="blog8.4" tag="p" />
        <Msg id="blog8.5" tag="p" />
        <Msg id="blog8.6" tag="p" />
        <Msg id="blog8.7" tag="p" />
      </>
    ),
  },
  {
    id: "9",
    title: "blog9.0",
    cover: "blog9",
    subTitle: "blog9.1",
    date: ["february", "06, 2025"],
    content: (
      <>
        <Msg id="blog9.1" tag="p" />
        <Msg id="blog9.2" tag="p" />
        <Msg id="blog9.3" tag="p" />
        <ul style={{ marginLeft: 20, listStyleType: "disc", paddingLeft: 20 }}>
          <Msg id="blog9.4" tag="li" />
          <Msg id="blog9.5" tag="li" />
          <Msg id="blog9.6" tag="li" />
        </ul>
        <Msg id="blog9.7" tag="p" />
      </>
    ),
  },
]
