import { CSSProperties, ReactNode } from "react"
import { Link } from "react-router-dom"
import { ReactComponent as LoadingSvg } from "./loading_icon.svg"
import "./style.css"

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "outlined" | "secondary" | "primary-light" | "white"
  minWidth?: number | "inherit"
  startIcon?: ReactNode
  loading?: boolean
}
export const Button = ({
  startIcon,
  variant = "primary",
  minWidth = 170,
  loading,
  ...props
}: IButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={`button button-${variant} ${props.className}`}
      style={{ minWidth: minWidth, ...props.style }}
    >
      {loading && (
        <div className="loading_bar">
          <LoadingSvg />
        </div>
      )}
      {startIcon}
      {props.children}
    </button>
  )
}

interface IArrowButtonProps {
  title: ReactNode
  to: string
  onClick?: () => void
  style?: CSSProperties
}

export const ArrowButton = ({ style, onClick, title, to }: IArrowButtonProps) => {
  return (
    <Link
      to={to}
      onClick={(e) => {
        if (onClick != null) {
          e.preventDefault()
          onClick()
        }
      }}
      className="arrow-button button button-secondary"
      style={style}
    >
      {title}
      <svg
        width="61"
        height="19"
        viewBox="0 0 61 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M3.5 10.0068H55.996" stroke="#694D97" strokeWidth={2} />
        <path
          d="M46.688 16.459C49.629 12.8892 53.823 10.5769 58.412 9.99402L58.687 9.95898L58.412 9.92394C53.823 9.34106 49.629 7.02872 46.688 3.45898"
          stroke="#694D97"
          strokeWidth={2}
        />
      </svg>
    </Link>
  )
}

interface IFaqBackButtonProps {
  to: string | number
  title: ReactNode
}

export const FaqBackButton = ({ to, title }: IFaqBackButtonProps) => {
  return (
    <Link className="button button-secondary button__faq-back" to={to as string}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="23"
        viewBox="0 0 15 23"
        fill="none"
      >
        <g clipPath="url(#clip0_252_10260)">
          <path
            d="M10.8003 4.94824L4.20024 11.4998L10.8003 18.0513"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_252_10260">
            <rect width="23" height="15" fill="white" transform="matrix(0 1 -1 0 15 0)"></rect>
          </clipPath>
        </defs>
      </svg>
      {title}
    </Link>
  )
}
